import React, { forwardRef, useImperativeHandle,useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import Stripe from './PaymentForm';

const stripePromise = loadStripe('sdadsa');


const StripePayment = forwardRef((props, ref) => {
return (
  <Elements stripe={stripePromise} options={{ locale: 'hu' }}>
    <div className="payment-container">
      <Stripe/>
    </div>
  </Elements>
)   
});

export default StripePayment;