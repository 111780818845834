import React, { useEffect, useRef, useState } from 'react';
import './Popup.css';
import StripePayment from './StripePayment';
import { useBase } from '../contexts/BaseContext';

const Popup = ({ onClose }) => {
    const stripeRef = useRef(null);
    const {quantity,setQuantity,formData,setFormData,handleQuantityChange,handleChange,popupOpen,setPopupOpen,popupType,setPopupType} = useBase();
  
 // Handle overflowY for html element
 useEffect(() => {
  const htmlElement = document.documentElement; // Get the <html> element
  if (popupOpen) {
      htmlElement.style.overflowY = 'hidden';
  } else {
      htmlElement.style.overflowY = 'scroll';
  }

  // Cleanup function to reset the overflowY property when the component unmounts
  return () => {
      htmlElement.style.overflowY = 'scroll';
  };
}, [popupOpen]);
  

  return (<>
    {popupOpen && <div className="popup-overlay">
      <div className="popup">
        <button className="close-button" onClick={()=>setPopupOpen(false)}>X</button>
        <div className="popup-content">
          <div className="product-card">
            <div className="card-header">
              <h2>Amarenti</h2>
              <div className="separator"></div>
            </div>
            { popupType === 1 ?
            <p>Black T-shirt<br /></p> 
            :
            <p>Midnightblue T-shirt<br /></p> 
            }
            <div className="image-container">
              { popupType === 1 ? 
                <img 
                src="images/new/fek2.JPEG" 
                alt="Amarenti Black T-shirt" 
                className="product-image" 
              />
              :
              <img 
              src="images/new/kek2.JPEG" 
              loading="lazy" 
              sizes="(max-width: 479px) 100vw, (max-width: 767px) 88vw, (max-width: 991px) 38vw, 36vw" 
              alt="" 
              className="termekkep2rolunk" 

              />
              }
            </div>
            <StripePayment ref={stripeRef}/>
          </div>
          <div className="form-section">
            <div className="quantity-selector">
              <button onClick={() => handleQuantityChange(-1)}>-</button>
              <div style={{marginLeft:10,marginRight:10}}>{quantity}</div>
              <button onClick={() => handleQuantityChange(1)}>+</button>
            </div>
            <p className="paragraph-5" style={{fontSize:16,fontWeight:"lighter",display:"flex",flexDirection:"row"}}>
              19 999Ft
                <p className="paragraph-5" style={{fontSize:16,fontWeight:"lighter",color:"gray",marginLeft:20,textDecoration:"line-through"}}>
                24 999Ft
                </p>
              </p>
            <div className="form-container">
              <form className="popup-form">
                <label>
                  Név:
                  <input type="text" name="name" value={formData.name} onChange={handleChange} required />
                </label>
                <label>
                  Email:
                  <input type="email" name="email" value={formData.email} onChange={handleChange} required />
                </label>
                <label>
                  Telefonszám:
                  <input type="tel" name="phone" value={formData.phone} onChange={handleChange} required />
                </label>
                <label>
                  Cégnév (opcionális):
                  <input type="text" name="company" value={formData.company} onChange={handleChange} />
                </label>
                <label>
                  Adószám (opcionális):
                  <input type="text" name="taxNumber" value={formData.taxNumber} onChange={handleChange} />
                </label>
                <label>
                  Ország:
                  <input type="text" name="country" value={formData.country} onChange={handleChange} required />
                </label>
                <label>
                  Város:
                  <input type="text" name="city" value={formData.city} onChange={handleChange} required />
                </label>
                <label>
                  Irányítószám:
                  <input type="text" name="postalCode" value={formData.postalCode} onChange={handleChange} required />
                </label>
                <label>
                  Cím:
                  <input type="text" name="address" value={formData.address} onChange={handleChange} required />
                </label>
                <label>
                  Megjegyzés (opcionális):
                  <textarea name="notes" value={formData.notes} onChange={handleChange}></textarea>
                </label>
              </form>
            </div>
            <div className="stripe-placeholder"></div>
            </div>
        </div>
      </div>
    </div> } </>
  );
};

export default Popup;