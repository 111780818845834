import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import React, { useState } from 'react';
import axios from 'axios';
import { ThreeDot } from 'react-loading-indicators';
import { useBase } from '../contexts/BaseContext';
// Initialize Stripe.js with your Stripe public key
//const stripePromise = loadStripe('pk_test_51PKmpUDjwHavD43pzMa6CtW6odldweGFMgIzDT4BEX0nAjceX9xx3PFkBcO1Z3qnWm0mqRs9PaFNFqUfow6oxdXR00DNpf351o');

const stripePromise = loadStripe('pk_live_51IGQ3lIGGmEAdvfOSNdARdt7qHSZjzg7FdFNRJUiKMGvxwNcEBsLnDQhLthYcf1hMTTrY1BMAbbvBcm0XgQCN5FT00qxVNZUvf');


const PaymentForm = () => {
    const {formData,w,quantity,type} = useBase();
    const [resDone,setResDone] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const API_BASE_URL = "https://amarenti.hu:41815";
  const [state,setState] = useState(0);
  const [err,setErr] = useState("ERROR");

  const formatDateToString = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  //const noPay = false;

  const handleSubmit = async (event) => {
    event.preventDefault();

    const { name, email, phone, country, city, postalCode, address } = formData;
    if (!name || !email || !phone || !country || !city || !postalCode || !address) {
        alert('Kérlek töltsd ki az összes kötelező mezőt!');
    } 
    //if (noPay) {return}
    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    if (!cardElement) {
      console.error("CardElement not found");
      return;
    }

    
    const { token, error } = await stripe.createToken(cardElement);
    setState(1);
    if (error) {
      console.error(error.message);
      setState(0);
    } else {
      console.log('Token:', token);
      if (token) {

        const paymentRequest = {
          email: formData.email,
          name: formData.name,
          country: formData.country,
          city: formData.city,
          line1: formData.address,
          postalCode: formData.postalCode,
          watNum: formData.taxNumber,
          companyName: formData.company,
          source: token.id, // Example token
          amount: 19999 * 100, // $50.00 in cents
          currency: "huf",
          phone: formData.phone,
          notes: formData.notes,
          quantity:quantity,
          type:type
        };

        setState(1);
         // Use try-catch block for error handling on the API request
         console.log('Van token baszod');
        try {
          // Send token, email, and planId to the backend
          const response = await fetch(`${API_BASE_URL}/api/auth/makePayment`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(paymentRequest),
          });
      
          if (!response.ok) {
            throw new Error(`Failed to make payment: ${response.statusText}`);
          }

          const data = await response.json();


          console.log('Backend response:', data);
          const { status, clientSecret } = data;

          console.log('Ide is beértünk');
          if (data.status === "ACTION_REQUIRED") {
              if (!clientSecret) {
                  console.error('No clientSecret returned from backend');
                  setState(3);  // Error state
                  return;
              }
  
              // Confirm the payment on the frontend (3D Secure or other actions)
              const { error: confirmError, paymentIntent } = await stripe.confirmCardPayment(clientSecret);
  
              if (confirmError) {
                  console.error('Payment confirmation error:', confirmError.message);
                  setState(3);  // Error state
              } else {
                  console.log('Payment Intent after confirmation:', paymentIntent);
                  if (paymentIntent.status === "succeeded") {
                      console.log('Payment successful!');
                      setState(2);  // Success state
                  } else {
                      console.error('Payment not successful. Status:', paymentIntent.status);
                      setState(3);  // Payment failed or requires further action
                  }
              } 
          } else {
              if (status === "SUCCESS") {
                  console.log('Payment successful!');
                  setResDone(true);
                  setState(2);  // Success state
              } else {
                  console.error('Payment not successful. Status:', status);
                  setState(3);  // Payment failed or requires further action
              }
          }
        
        // Handle subscription response
        }  catch (apiError) {
          // Check if apiError is an AxiosError (specific to Axios)
          if (axios.isAxiosError(apiError)) {
              if (apiError.response) {
                  // Access the response data from the server
                  console.error('API request failed:', apiError.response.data);
                  
                  // If the API returned a message in the response, handle it
                  const errorMessage = apiError.response.data.message || 'An error occurred';
                  setErr(errorMessage);  // Set the error message in your state
                  setState(3);
              } else if (apiError.request) {
                  // The request was made, but no response was received
                  console.error('No response received:', apiError.request);
                  setState(3);
              } else {
                  // Something happened in setting up the request
                  console.error('Error setting up request:', apiError.message);
                  setErr(apiError.message);
                  setState(3);
              }
          } else {
              // Handle any other types of errors (if needed)
              console.error('An unexpected error occurred:', apiError);
              setState(3);
          }
      }
      } else {
        console.error('Token creation error', error);
        setState(0);
      }
    }
  };

  

  const cardStyle = {
    style: {
      base: {
        fontSize: w<1000?'16px':'20px', // Adjust the font size
        fontWeight: "600",
        color: '#1a2238', // Change the text color
        letterSpacing: '0.025em',
        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
        '::placeholder': {
          color: '#aab7c4',
        },
        padding: '20px', // Add padding to make it bigger
      },
      invalid: {
        color: '#9e2146', // Change the color for invalid inputs
      },
    },
  };

  return (<>
    { state === 0 ? 
    <form className="stripe-form" onSubmit={handleSubmit} style={{marginTop:"20px"}}>
        <CardElement options={cardStyle}/>
        <button className="formpopupbuttom" style={{marginTop:40}} type="submit" onClick={handleSubmit}>Vásárlás</button>
    </form>
    :
   state === 1 ? 
    <form className="stripe-form" style={{marginTop:"20px"}}>
      <ThreeDot variant="bounce" color="#d4d02e" size="medium" text={""} textColor="" />
    </form>
    :
    state === 2 ?
    <form className="stripe-form" style={{marginTop:"20px"}}>
    <div className="ms2-inline-block" style={{marginTop:"20px"}}>
    <div className="btn-reserve2-done">Sikeres Foglalás</div>
    </div>
  </form>
  :
  state === 3 ?
  <form className="stripe-form" style={{marginTop:"20px"}}>
  <div className="ms2-inline-block" style={{marginTop:"20px"}}>
  <div className="btn-reserve2-done">Sikertelen Foglalás</div>
  </div>
</form>
    :
    <>
    <form className="stripe-form" style={{marginTop:"20px"}}>
    <div className="ms2-inline-block" style={{marginTop:"20px"}}>
    <div className="btn-reserve2-done">Sikeres Foglalás</div>
    </div>
  </form>
    </>
    }
    </>
  );
};

const Stripe = () => (
  <Elements stripe={stripePromise}>
    <PaymentForm />
  </Elements>
);

export default Stripe;